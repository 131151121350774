import { ViewIcon } from "@chakra-ui/icons";
import {
  Heading,
  IconButton,
  Image,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { maskInfoType, masksInfo } from "../Constant";

export interface MaskTableProps {}

export const MaskTable: React.FC<MaskTableProps> = () => {
  const [filterString, setFilterString] = useState("");
  return (
    <VStack width="100vw">
      <Input
        placeholder="輸入口罩名稱"
        onChange={(event) => setFilterString(event.target.value)}
        value={filterString}
      />
      <Table variant="striped" colorScheme="teal" size="sm">
        <TableCaption>
          資料來源:{" "}
          <Link
            href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjauJDKpNjvAhXQFogKHTzHAgYQFjAAegQIAhAD&url=https%3A%2F%2Fwww.consumer.org.hk%2Fsites%2Fconsumer%2Ffiles%2F530_disposable_masks.pdf&usg=AOvVaw17iDjkyaZ9KlYPK2ihMLy0"
            isExternal
          >
            消費者委員會
          </Link>
        </TableCaption>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>名</Th>
            <Th isNumeric>$</Th>
            <Th isNumeric>分</Th>
          </Tr>
        </Thead>
        <Tbody>
          {masksInfo
            .sort(
              (a: maskInfoType, b: maskInfoType) => b.finalScore - a.finalScore
            )
            .filter(
              (info: maskInfoType) =>
                info.engName
                  .toLowerCase()
                  .includes(filterString.toLowerCase()) ||
                info.chiName.includes(filterString)
            )
            .map((info: maskInfoType) => (
              <Tr>
                <Td>
                  {info.image || info.chiName.length > 0 ? (
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <IconButton
                          aria-label="View more"
                          icon={<ViewIcon />}
                          size="sm"
                        />
                      </PopoverTrigger>
                      <PopoverContent maxWidth="300px">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <VStack spacing={4} align="stretch">
                            {info.chiName ? (
                              <VStack spacing={2} align="stretch">
                                <Heading as="h2" size="sm">
                                  中文名
                                </Heading>
                                <Text>{info.chiName}</Text>
                              </VStack>
                            ) : undefined}
                            {info.image ? (
                              <VStack spacing={2} align="stretch">
                                <Heading as="h2" size="sm">
                                  參考圖片
                                </Heading>
                                <Image
                                  boxSize="300px"
                                  objectFit="contain"
                                  src="/masks/amag.png"
                                  alt={`${info.engName} image`}
                                />
                              </VStack>
                            ) : undefined}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  ) : (
                    ""
                  )}
                </Td>
                <Td>{info.engName}</Td>
                <Td isNumeric>${info.priceForOne}</Td>
                <Td isNumeric>{info.finalScore}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </VStack>
  );
};
