import {
  Box,
  ChakraProvider,
  Flex,
  Heading,
  theme,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { MaskTable } from "./components/MaskTable";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <VStack spacing={4}>
        <Flex justify="space-between" width="100vw" align="center">
          <Heading as="h1" p={2} size="lg">
            😷 消委會口罩
          </Heading>
          <ColorModeSwitcher justifySelf="flex-end" />
        </Flex>
        <MaskTable />
      </VStack>
    </Box>
  </ChakraProvider>
);
